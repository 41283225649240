import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ClientsForm from "../components/clientsForm";
import Title from "../components/title";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { FaRegWindowClose } from "react-icons/fa";
export const _frontmatter = {
  "title": "Clients",
  "calltoaction": "Tell us about your staffing needs",
  "description": "Are you a hospital, SNF, homehealth, or clinic looking for staff? Do you need staff who are experienced in a specific department? We're listening.",
  "tags": ["clients", "request", "rn", "cna", "lvn", "pt", "ot", "st", "iv certified", "rehab", "find staff", "staff", "myriad healthcare", "healthcare"],
  "path": "/clients",
  "date": "2019-11-07T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ModalRoutingContext.Consumer>
    {({
        modal,
        closeTo
      }) => <div>
        {modal ? <>
          <Link to={closeTo} mdxType="Link">
            Close
          </Link>
          <Link to={closeTo} mdxType="Link">
            <FaRegWindowClose style={{
              float: `right`
            }} title="Close window" mdxType="FaRegWindowClose" />
          </Link>
          <div className="modal-header" style={{
            backgroundColor: `orange`
          }}>
            <h1 className="modal-title">{props.pageContext.frontmatter.title}</h1>
            <p className="modal-description">{props.pageContext.frontmatter.description + " " + props.pageContext.frontmatter.calltoaction}.</p>
          </div>
          <ClientsForm mdxType="ClientsForm" /></> : <Layout mdxType="Layout">
            <Title title={props.pageContext.frontmatter.title} filename="handshake.jpg" calltoaction={props.pageContext.frontmatter.calltoaction} mdxType="Title" />
            <SEO title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="SEO" />
            <div className="centered-content"> 
                <p className="form-description">{props.pageContext.frontmatter.description}</p> 
                <ClientsForm mdxType="ClientsForm" />
            </div>
          </Layout>}
        </div>}
    </ModalRoutingContext.Consumer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      